import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "../../ui/sheet";
import { Separator } from "../../ui/separator";
/*   import NavItems from "./NavItems" */
import { FaBars } from "react-icons/fa";
import { SubNavigation } from "./NavItems";
import Search from "./Search";

const MobileNav = () => {
  return (
    <nav className="lg:hidden sticky top-0">
    <Sheet key="left">
      <SheetTrigger className="align-middle">
        <FaBars size="26px" color="white"/>
      </SheetTrigger>
      <SheetContent className="flex flex-col gap-6 bg-white lg:hidden" side="left">
        <img 
          src="/assets/logo/demologo.jpg"
          alt="logo"
          width={128}
          height={38}
        />
        <Separator className="border border-gray-100" />
        <div>
          <Search/>
          <SubNavigation/>
        </div>
      </SheetContent>
    </Sheet>
  </nav>
  );
};

export default MobileNav;
