import React from 'react'
import { MdAccountCircle } from 'react-icons/md';
import { NavLink, useNavigate } from 'react-router-dom';
import { Sheet, SheetContent, SheetTrigger } from "../../ui/sheet";
import { Separator } from "@radix-ui/react-select";
import { Button } from "../../ui/button";
import { useDispatch } from 'react-redux';
import { removeCredentials } from '../../../redux/features/authDetails';

const UserIconStatus = () => {
    const currentUser=JSON.parse(localStorage.getItem("cpauser")!);
    
  return (
    <>
{!currentUser || !currentUser?.token  ? (
        <NavLink to="/signin"> 
          <MdAccountCircle size={28} />
        </NavLink>
      ) : (
        <UserIcon />
      )}
    </>
  )
}

export const  UserIcon= () => {
    const navigate=useNavigate()
    const dispatch = useDispatch()
    const currentUser=JSON.parse(localStorage.getItem("cpauser")!);
    const handleSignout=async()=>{
      localStorage.removeItem("cpauser")
      dispatch(removeCredentials())
      navigate("/signin"); 
      window.location.reload();
    }
  
    return (
      <nav className="sticky top-0">
     <Sheet key="right" >
    <SheetTrigger className="align-middle">
      <MdAccountCircle size={28} />
    </SheetTrigger>
   <SheetContent className="flex flex-col gap-6 bg-white" side="right">
      {/* <img 
        src="/assets/logo/demologo.jpg"
        alt="logo"
        width={128}
        height={38}
      /> */}
      <p className="text-lg font-semibold">Customer Details</p>
      <Separator className="border border-gray-100" />
      <div className="flex flex-col gap-4 text-md font-semibold">
      {currentUser?.token &&(<>
      <div className="flex justify-between">
        <p>Fullname:</p>
        <p>{currentUser?.fullName}</p>
      </div>
      <div className="flex justify-between">
        <p>Mobile:</p>
        <p>{currentUser?.userName}</p>
      </div>
      <Separator className="border border-gray-100" />
      <NavLink to="/myorders" className="text-md font-bold text-red-800">My Orders</NavLink>
      <Separator className="border border-gray-100" />
      </>)}
      <div className="flex justify-between mt-8">
       <NavLink to="/userdetails">Edit Details</NavLink>
       <Button onClick={handleSignout}>SignOut</Button>
      </div>
      </div>
    </SheetContent>
  </Sheet>
    </nav>
    );
  };
export default UserIconStatus