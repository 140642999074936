import {useParams } from 'react-router-dom'
import SareePage, { Product } from '../components/shared/Detail/SareePage'
import { useGetProductByIdQuery } from '../redux/slice/ProductsApiSlice'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { DEMOAPIURL } from '../constants/appConfig'
import { ProductDemo } from '@/react-app-env'

const SareeDetail = () => {
    
    const{id}=useParams()
    const { data: product, isError, isLoading, isSuccess, error} = useGetProductByIdQuery(id)
   
    let content
  
    if (isLoading) content = <p>Loading........</p>
  
    if (isError) content = <p>{product?.result?.message}</p>
    if (!product?.result){
      content= <section className="bgcolorgold" >
        <p className="p-4 text-center text-lg font-semibold">
          No Sarees Available By ID
        </p></section>}
    if (isSuccess&& product?.result){
      content=  <SareePage saree={product?.result} />
    }
    return (
        <div className="py-5 bgcolorgold">
       {content}
    </div>
    )
}

export const ProductDetail = () => {
    
    const{id}=useParams()
    const [product, setProduct] = useState<ProductDemo | null>(null); 

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${DEMOAPIURL}${id}`);
          setProduct(response.data);
        } catch (error) {
          // Handle errors here
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData(); // Call the async function
    }, [id])

    let content

  
  /*   if (isLoading) content = <p>Loading........</p>
  
    if (isError) content = <p>{product?.result?.message}</p>
    if (!product?.result){
      content= <section className="bgcolorgold" >
        <p className="p-4 text-center text-lg font-semibold">
          No Sarees Available By ID
        </p></section>}
    if (isSuccess&& product?.result){
      content=  <SareePage saree={product?.result} />
    } */
    if (product ){
      content= <Product saree={product} />
    
    }else{
      content= <section className="bgcolorgold" >
      <p className="p-4 text-center text-lg font-semibold">
        No Sarees Available Under This Category 
      </p></section>
    }
    return (
        <div className="py-5 bgcolorgold">
       {content}
    </div>
    )
}

export default SareeDetail



  