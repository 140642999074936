import {
  Form,
  FormControl,
  FormItem,
  FormField,
  FormLabel,
  FormMessage,
} from "../../../ui/form";
import { Input } from "../../../ui/input";
import { useForm } from "react-hook-form";
import { Button } from "../../../ui/button";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useNavigate } from "react-router-dom";
import { Textarea } from "../../../ui/textarea";
import { useEffect, useState } from "react";
import { IMGURL } from "../../../../constants/appConfig";
import { ProductWithImages } from "@/react-app-env";
import { productDefaultValues } from "../../../../constants/index";
import Dropdown, { AddDeleteColor, ColorsSelect } from "./Dropdown";
import productFormSchema from "./Validator";
import { useAddNewProductMutation } from "../../../../redux/slice/admin/AProductsApiSlice";
import { useDeleteProductImagesAMutation } from "../../../../redux/slice/admin/AImagesUploadApiSlice";
import { AlertDialog, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "../../../../components/ui/alert-dialog";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoIosClose } from "react-icons/io";


type EventFormProps = {
  userId?: string;
  type: "Create" | "Update";
  product?: ProductWithImages;
  eventId?: string;
};
type colorIdT={
  id:number,
  colorCode:string
}
const ProductForm = ({type, product}: EventFormProps) => {
  const [imageBase64, setImageBase64] = useState<string | undefined>();
  const [colorIdArr, setColorIdArr] = useState<colorIdT[]>([{id: 1, colorCode: '#FF0000'}]);
  const [colorIdU, setColorIdU] = useState<colorIdT>({id:product?.colorId!,colorCode:product?.colorCode!});
  const[addEditProduct,{isError,isSuccess,data}]= useAddNewProductMutation()
  const [catId,setCatId]=useState<number|undefined>(type==="Create"?1:product?.categoryId)
  const handleCatId=(id:number)=>{setCatId(id)}
  const navigate=useNavigate()

  
  useEffect(()=>{
    if(isSuccess&& data?.success){
      navigate("/admin/dashboard/products")
    }else if(isError){
      if(type==="Create"){
        alert("can't add new product")
      }else{
        alert("can't edit/update product")
      }
    }
  },[isError,isSuccess,navigate,type,data])

  const initialValues = product && type === "Update" ? { ...product } : productDefaultValues;
  const handleRemoveColorLoc=(color:any)=>{
    setColorIdArr(colorIdArr.filter(col=>col.id!==color.id))
  }

  const form = useForm<z.infer<typeof productFormSchema>>({
    resolver: zodResolver(productFormSchema),
    defaultValues: initialValues
  });

  async function onSubmit(values: z.infer<typeof productFormSchema>) {
   //  console.log({...values,thumbnail:imageBase64?imageBase64:product?.thumbnail,colorId:colorIdArr,colorIdU});
    if(type === "Create"){
      try {
        await Promise.all(colorIdArr.map(async (color) => {
          const productData = { ...values, image: imageBase64, colorId: color.id };
          /* const result = */ await addEditProduct(productData);
         // console.log('Product Added:', result);
        }));
      } catch (error) {
        console.log(error);
      }
    }
    if(type === "Update"){
      try {
        /* const editProduct = */ await addEditProduct({...values,colorId:colorIdU.id,image:imageBase64?.length?(imageBase64):(product?.thumbnail),id:product?.id,isThumnailchanged:imageBase64?.length&&true})
      //  console.log(editProduct);
        
      } catch (error) {
        console.log(error);
      }
    }
  } 
  
  /* const handleDeleteImage=async(id:string)=>{
    const shouldDelete = window.confirm('Are you sure you want to delete this Image?');
    if (shouldDelete && type==="Update") {
      await deleteImage(id);
    } 
  } 
   async function onSubmit(values: z.infer<typeof productFormSchema>) {
    console.log(values);  
  } */
  
  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-5"
      >
        <div className="flex flex-col gap-5 items-center md:flex-row">
          <FormField
            control={form.control}
            name="productName"
            render={({ field }) => (
              <FormItem className="w-full ">
                <FormLabel>Product Name</FormLabel>
                <FormControl>
                  <Input placeholder="Enter Product Name" className="input-field" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex flex-col gap-5 items-center md:flex-row">
          <FormField
            control={form.control}
            name="categoryId"
            render={({ field }) => (
              <FormItem className="w-full ">
                 <FormLabel htmlFor="categoryIdDropdown">Category</FormLabel>
                <FormControl>
                  <Dropdown
                   id="categoryIdDropdown"
                    onChangeHandler={field.onChange}
                    value={field.value.toString()}
                    type="categoryId"
                    onChangeCID={handleCatId}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="subcategoryId"
            render={({ field }) => (
              <FormItem className="w-full ">
                <FormLabel htmlFor="subCategoryIdDropdown">SubCategory</FormLabel>
                <FormControl>
                  <Dropdown
                   id="subCategoryIdDropdown"
                    onChangeHandler={field.onChange}
                    value={field.value.toString()}
                    type="subCategoryId"
                    catId={catId}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        {/* <div className="flex flex-col gap-5 items-center md:flex-row">
          <FormField
            control={form.control}
            name="descriptionShort"
            render={({ field }) => (
              <FormItem className="w-full ">
                <FormLabel>Description Short</FormLabel>
                <FormControl className="h-96">
                  <Textarea
                    placeholder="Enter descriptionShort"
                    {...field}
                    className="textarea rounded-2xl"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div> */}
        <div className="flex flex-col gap-5 items-center md:flex-row">
          <FormField
            control={form.control}
            name="productDescription"
            render={({ field }) => (
              <FormItem className="w-full ">
                <FormLabel>Description Long</FormLabel>
                <FormControl className="h-96">
                  <Textarea
                    placeholder="Description Long"
                    {...field}
                    className="textarea rounded-2xl"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex flex-col md:flex-row items-center gap-5">
          <FormField
            control={form.control}
            name="priceINR"
            render={({ field }) => (
              <FormItem className="w-full ">
                <FormLabel>Price in Rupess (INR)</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter priceINR"
                    {...field}
                    className="input-field"
                    min={1}
                    onChange={event => field.onChange(+event.target.value)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="priceUSD"
            render={({ field }) => (
              <FormItem className="w-full ">
                <FormLabel>Price in doller (USD)</FormLabel>
                <FormControl className="h-72">
                  <Input 
                  placeholder="Enter Price in doller (USD)"
                    {...field}
                    className="input-field"
                    min={1}
                    onChange={event => field.onChange(+event.target.value)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex flex-col md:flex-row items-center gap-5">
          <FormField
            control={form.control}
            name="availableQuantity"
            render={({ field }) => (
              <FormItem className="w-full ">
                <FormLabel>Available Quantity</FormLabel>
                <FormControl>
                  <Input placeholder="Enter availableQuantity" {...field} className="input-field"  min={1}
                    onChange={event => field.onChange(+event.target.value)}/>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="soldQuantity"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Sold Quantity</FormLabel>
                <FormControl>
                  <Input placeholder="Enter soldQuantity" className="input-field" {...field}  min={0}
                    onChange={event => field.onChange(+event.target.value)}/>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
           <FormField
            control={form.control}
            name="discount"
            render={({ field }) => (
              <FormItem className="w-full ">
                <FormLabel>Discount (%)</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter Discount"
                    {...field}
                    className="input-field"
                    min={0}
                    onChange={event => field.onChange(+event.target.value)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex flex-col gap-5 items-center md:flex-row">
          <div className="flex flex-col gap-2">
            <FormField
              control={form.control}
              name="thumbnail"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Choose Image Thumbnail</FormLabel>
                  <FormControl>
                    <Input
                      type="file"
                      accept="image/*, application/pdf"
                      className="input-field"
                      onChange={(event) => {
                        const file = event.target.files && event.target.files[0];
                        if (file) {
                          const reader = new FileReader();
                          reader.onload = () => {
                            const base64String = reader.result as string;
                            setImageBase64(base64String);
                          };
                          reader.readAsDataURL(file); // Convert the selected file to Base64
                        }
                      }}
                    />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {imageBase64 ? (<img src={imageBase64} alt="imgphot" width={100} height={100} />) : (
                          product?.thumbnail !== undefined && (<img src={`${IMGURL}${product?.thumbnail}`} alt="imgphot" width={100} height={100} />)
                        )
          }
          </div>
         {type==="Create"?<div className="flex flex-col gap-2">
          <FormField
            control={form.control}
            name="colorId"
            render={({ field }) => (
              <FormItem className="w-full ">
                <FormControl>
                  <ColorsSelect
                    onChangeHandler={(event:any) => {
                     setColorIdArr(pre=>[...pre,event])
                    }}
                    value={field.value}
                    type="Create"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {colorIdArr.length>0&&<div className="flex flex-wrap gap-4">
            <p className="text-sm font-semibold">Selected Colors:</p>
          {colorIdArr.map(color => <div key={color.id} className="relative ">
          <p className="rounded-full w-[40px] h-[40px]" style={{ backgroundColor: color.colorCode }}></p>
          <IoIosClose className="absolute top-2 left-2" size={22} color="white" onClick={()=>handleRemoveColorLoc(color)}/>
          </div>)}
          </div>
          }
        </div>:<div>
          <FormField
            control={form.control}
            name="colorId"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormControl>
                  <ColorsSelect
                    onChangeHandler={(event:any) => {
                      setColorIdU(event)
                     }}
                    value={field.value}
                    type="Update"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
                )}
           />
           {<div className="flex gap-2">
            <p className="text-sm font-semibold">Selected Color:</p>
            <p className="w-[30px] h-[30px] rounded-full" style={{backgroundColor:colorIdU.colorCode}}></p>
            </div>}
        </div>}
        <div>
        <AddDeleteColor/>
        </div>
        </div>
        {(type==="Update"&& product?.images?.length!>0)&& <div className="flex flex-col gap-4 my-4">
          <p className="text-md font-medium mb-2">Select Image to Delete</p>
          <div className="flex gap-4">
          {product?.images.map((image)=>{
            return <div className="relative"><img src={`${IMGURL}${image.imageUrl}`} alt={image.imageUrl} width={100} height={100}/>
            <AlertDialogDemo image={image}/>
            </div>
          })}
          </div>
          </div>}
        <div className="flex md:justify-between items-center md:flex-row flex-col">
        <Button
          type="submit"
          size="lg"
          className="button col-span-1 headermenu"
        >
          { `${type} Product`}
        </Button>
        <Button
        onClick={()=>{navigate(-1)}}
          size="lg"
          className="button col-span-1 headermenu"
        >
         cancel
        </Button>
        </div>
      </form>
    </Form>
  );
};


export function AlertDialogDemo({image}:any) {
const[deleteImage]=useDeleteProductImagesAMutation()

   const handleRemoveImg =async()=>{
    await deleteImage(image?.id)
    window.location.reload()
   }

    return (
      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button variant="ghost">Delete<RiDeleteBin6Line size={20} /></Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently remove the  Image.
              <img src={`${IMGURL}${image.imageUrl}`} alt={image.imageUrl} width={200} height={200}/>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <Button onClick={handleRemoveImg}>Delete</Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }

export default ProductForm;
