
import { Card } from "../../ui/card";
import { shopByPriceCollection } from "../../../constants/index";
import { MdCurrencyRupee } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaAngleRight } from "react-icons/fa6";
import { FaAngleLeft } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { FaDollarSign } from "react-icons/fa";
import { Link } from "react-router-dom";


const ShopByPrice = () => {
  const {currency} = useSelector((state: RootState)=>state.counterCurrency)
  
  const settings = {
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    prevArrow: <FaAngleLeft size={48} color="#570909" />,
    nextArrow: <FaAngleRight size={48} color="#570909" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="wrapper-newprice bgcolorgold bg-dotted-pattern bg-contain py-3 md:py-4">
      <div className="flex justify-center">
        <h5 className="h3-bold">Shop By Price</h5>
      </div>
      <div className="pt-3">
        <Slider {...settings} className="flex gap-4 my-4">
          {shopByPriceCollection?.map((shopbyprice) => (
            <Card
              key={shopbyprice.id}
              className="w-[230px] h-[400px] mx-2 relative"
            >
              <Link
               to={`/shopbyprice/${currency === "USD" ? shopbyprice.priceUSD : shopbyprice.priceINR}`}
          className="hover:z-10"
              >
                {/* <div className=" border-gray-400 rounded-lg border-2 absolute top-5 left-5 right-5 bottom-5"></div> */}
               <img
                  src={shopbyprice.url}
                  alt={currency==="USD"?"priceUSD":"priceINR"}
                  width={200}
                  height={150}
                  className="rounded-lg w-full h-full"
                />
                <div className="flex justify-center flex-col items-center p-1 absolute top-[40%] left-[30%] z-10 text-white">
                  <p className="text-3xl font-bold">Under</p>
                  <p className="text-3xl font-bold flex gap-1 items-center">
                  {/*  {currency==="USD"? <><FaDollarSign size={20} />{shopbyprice.priceUSD}</>:
                   <><MdCurrencyRupee size={24} />{shopbyprice.priceINR}</>} */}
                { new Intl.NumberFormat('en-US', { style: 'currency', currency: currency,maximumFractionDigits:0 }).format(currency==="USD"?shopbyprice.priceUSD:shopbyprice.priceINR)}
                  </p>
                </div>
              </Link>
            </Card>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default ShopByPrice;
