

import React, { useEffect, useRef } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import Slider from "react-slick";

const Video = () => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = videoRef.current;
    const playVideo = () => {
      if (video) {
        video.play().catch(error => {
          console.error('Auto-play was prevented:', error);
        });
      }
    };
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        playVideo();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    playVideo();
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <video
      ref={videoRef}
      width="100%"
      style={{ maxWidth: '100%'}}
      autoPlay
      loop
      muted
      playsInline
    >
      <source src="/assets/CPCA-video.mp4" type="video/mp4" />
    </video>
  );
};



export function SliderDemo() {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <FaAngleLeft size={48} color="blue" />,
    nextArrow: <FaAngleRight size={48} color="blue" />,
  };
  return (
    <div className="slider-container my-2  wrapper-weaves" >
      <Slider {...settings}>
        <div>
         <img src='/assets/video/s1.webp' alt='s1' className='w-full h-[450px] mx-auto'/>
        </div>
        <div>
        <img src='/assets/video/s2.webp' alt='s1' className='w-full h-[450px]  mx-auto'/>
        </div>
        <div>
        <img src='/assets/video/s3.jpeg' alt='s1' className='w-full h-[450px] mx-auto'/>
        </div>
        <div>
        <img src='/assets/video/s4.jpg' alt='s1' className='w-full h-[450px] mx-auto'/>
        </div>
        <div>
        <img src='/assets/video/s5.jpg' alt='s1' className='w-full h-[450px] mx-auto'/>
        </div>
      </Slider>
    </div>
  );
}



export default Video;







