import { useParams } from "react-router-dom"
import UploadImages from "../../components/shared/admin/components/UploadImages"


const UploadImagesPage = () => {
    const {id}=useParams()
    return (
        <div className="w-full md:mx-20 md:my-10 border-2 border-gray-300 md:p-4">
        <h5 className="h5-bold mb-4">Upload More Images for Product</h5>
         <UploadImages id={id!}/>
      </div>
      )
}

export default UploadImagesPage