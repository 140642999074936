
import { MdCurrencyRupee } from "react-icons/md"
import { Button } from "../../ui/button"
import { FaRegHeart } from "react-icons/fa"
import { Fragment, useEffect, useState } from "react"
import ImagesCol, { ImagesColdemo } from "./ImagesCol"
import { useDispatch, useSelector } from "react-redux"
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsCurrencyDollar } from "react-icons/bs"
import { IMGURL } from "../../../constants/appConfig"
import { ProductDemo, ProductWithImages } from "@/react-app-env"
import { RootState } from "@/redux/store"
import { addSareeToCart } from "../../../redux/features/cartSlice"
import { addSareeToWhishlist } from "../../../redux/features/whishlistSlice"
import { useAddItemCartMutation } from "../../../redux/slice/CartApiSlice"
import { useAddItemWishlistMutation } from "../../../redux/slice/Wishlist"
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { useLocation } from "react-router-dom"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog"
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { FaFacebook, FaShare, FaSquareWhatsapp, FaXTwitter } from "react-icons/fa6"
import { IoIosLink } from "react-icons/io"
// import { Helmet } from "react-helmet";
import { Helmet } from 'react-helmet-async';
const SareePage = ({ saree }: { saree: ProductWithImages }) => {

  let images = saree.images
  let imageslength = images.length
  const [imagesCount, setImagesCount] = useState<number>(0)
  const [imgCK, setImgCk] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { cartItems } = useSelector((state: RootState) => state.counterCart)
  const { currency } = useSelector((state: RootState) => state.counterCurrency)
  const existingItem = cartItems?.filter(item => item.id === saree.id)
  const availableCount = existingItem.length > 0 ? existingItem[0].count : 0
  const currentUser = JSON.parse(localStorage.getItem("cpauser")!);
  const [AddItemCart] = useAddItemCartMutation()
  const [AddItemWishlist] = useAddItemWishlistMutation()


  const handleCart = async () => {
    if (availableCount < saree.availableQuantity) {
      toast("Added to cart", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
        progress: undefined,
        transition: Bounce,
        style: {
          background: 'linear-gradient(to right, #570909, #A52A2A)',
          color: 'white',
        },
      })
      if (currentUser?.token) {
        try {
          const response = await AddItemCart({ customerId: currentUser.refId, productId: saree.id, qty: 1, readyToBuy: 1 });
          if ('error' in response) {
            console.error('error:', response.error);
            dispatch(addSareeToCart({ ...saree }))
            return;
          }
          const { success, result } = response?.data;

          console.log(success, result);

          /*  if (success && result)navigate("/cart") */
        } catch (error) {
          console.error('Error adding to cart:', error);
        }
      } else {
        dispatch(addSareeToCart({ ...saree }))
      }
    } else {
      toast("Available quantity is fully added to cart", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
        progress: undefined,
        transition: Bounce,
        style: {
          background: 'linear-gradient(to right, #00b09b, #96c93d)',
          color: 'white',
        },
      })
    }
  }

  const handleWishlist = async () => {
    toast("Added to wishlist", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "dark",
      progress: undefined,
      transition: Bounce,
      style: {
        background: 'linear-gradient(to right, #570909, #A52A2A)',
        color: 'white',
      },
    })
    if (currentUser?.token) {
      try {
        const response = await AddItemWishlist({ customerId: currentUser.refId, productId: saree.id });
        if ('error' in response) {
          console.error('error:', response.error);
          dispatch(addSareeToWhishlist({ ...saree }))
          return;
        }
        const { success, result } = response?.data;

        console.log(success, result);

      } catch (error) {
        console.error('Error adding to wishlist:', error);
      }
    } else {
      dispatch(addSareeToWhishlist({ ...saree }))
    }
  }

  return (
    <div className="grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-6  wrapper-weaves">
      <div className="flex md:flex-row flex-col  gap-3 ">
        {imageslength > 0 && <ImagesCol setcount={setImagesCount} ImgCk={setImgCk} images={images} />}
        {/* <div className="w-[85%] h-[50%]"> */}
        <Zoom>
          <img
            src={imgCK ? `${IMGURL}${images[imagesCount].imageUrl}` : `${IMGURL}${saree.thumbnail}`}
            alt={saree.productName}
            style={{ width: "620px" }}
            className="rounded-md bg-cover w-[85%] h-full flex justify-end"
          />
        </Zoom>
      </div>
      <div className="flex flex-col gap-3">
        <h4 className="text-xl font-bold">{saree.productName}</h4>
        <p className="text-xl font-bold flex items-center border-b-2 border-gray-300">
          {/* {currency === "USD" ? <><BsCurrencyDollar size={20} />{saree.priceUSD}</> : <><MdCurrencyRupee size={24} />{saree.priceINR}</>} */}
          { new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(currency==="USD"?saree.priceUSD:saree.priceINR)}
          {saree.discount! > 0 && <span className=" px-2 text-sm text-red-500">-{saree.discount}%</span>}
        </p>
        <div>
          <h3 className="text-lg font-bold">Product Description</h3>
          <p className="p-regular-16">{saree.productDescription}</p>
        </div>

        <div className="flex gap-3 ">
          <div>
            <Button className="headermenu" onClick={handleCart} disabled={availableCount >= saree.availableQuantity}>Add To Cart</Button>
            {availableCount >= saree.availableQuantity && <p className="text-red-600 text-sm font-semibold px-1">Out Of Stock</p>}
          </div>
          <Button className="bg-red-500" onClick={handleWishlist}><FaRegHeart size={20} className="pr-2" />Add To WishList</Button>
          <div className="px-2">
            <ShareModel saree={saree} />
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export const Product = ({ saree }: { saree: ProductDemo }) => {

  let images = saree.images
  let imageslength = images.length
  const [imagesCount, setImagesCount] = useState<number>(0)
  const [imgCK, setImgCk] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { cartItems } = useSelector((state: RootState) => state.counterCart)
  const { currency } = useSelector((state: RootState) => state.counterCurrency)
  const existingItem = cartItems?.filter(item => item.id === saree.id)
  const availableCount = existingItem.length > 0 ? existingItem[0].count : 0
  const currentUser = JSON.parse(localStorage.getItem("cpauser")!);
  const [AddItemCart] = useAddItemCartMutation()
  const [AddItemWishlist] = useAddItemWishlistMutation()


  // const handleCart = async () => {
  //   if (availableCount < saree.availableQuantity) {
  //     toast("Added to cart", {
  //       position: "top-center",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       theme: "dark",
  //       progress: undefined,
  //       transition: Bounce,
  //       style: {
  //         background: 'linear-gradient(to right, #570909, #A52A2A)',
  //         color: 'white',
  //       },
  //     })
  //     if (currentUser?.token) {
  //       try {
  //         const response = await AddItemCart({ customerId: currentUser.refId, productId: saree.id, qty: 1, readyToBuy: 1 });
  //         if ('error' in response) {
  //           console.error('error:', response.error);
  //           dispatch(addSareeToCart({ ...saree }))
  //           return;
  //         }
  //         const { success, result } = response?.data;

  //         console.log(success, result);

  //         /*  if (success && result)navigate("/cart") */
  //       } catch (error) {
  //         console.error('Error adding to cart:', error);
  //       }
  //     } else {
  //       dispatch(addSareeToCart({ ...saree }))
  //     }
  //   } else {
  //     toast("Available quantity is fully added to cart", {
  //       position: "top-center",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       theme: "dark",
  //       progress: undefined,
  //       transition: Bounce,
  //       style: {
  //         background: 'linear-gradient(to right, #00b09b, #96c93d)',
  //         color: 'white',
  //       },
  //     })
  //   }
  // }

  // const handleWishlist = async () => {
  //   toast("Added to wishlist", {
  //     position: "top-center",
  //     autoClose: 2000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     theme: "dark",
  //     progress: undefined,
  //     transition: Bounce,
  //     style: {
  //       background: 'linear-gradient(to right, #570909, #A52A2A)',
  //       color: 'white',
  //     },
  //   })
  //   if (currentUser?.token) {
  //     try {
  //       const response = await AddItemWishlist({ customerId: currentUser.refId, productId: saree.id });
  //       if ('error' in response) {
  //         console.error('error:', response.error);
  //         dispatch(addSareeToWhishlist({ ...saree }))
  //         return;
  //       }
  //       const { success, result } = response?.data;

  //       console.log(success, result);

  //     } catch (error) {
  //       console.error('Error adding to wishlist:', error);
  //     }
  //   } else {
  //     dispatch(addSareeToWhishlist({ ...saree }))
  //   }
  // }

  return (
    <div className="grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-6  wrapper-weaves">
      <div className="flex md:flex-row flex-col  gap-3 ">
        {imageslength > 0 && <ImagesColdemo setcount={setImagesCount} ImgCk={setImgCk} images={images} />}
        {/* <div className="w-[85%] h-[50%]"> */}
        <Zoom>
          <img
            src={imgCK ? `${images[imagesCount]}` : `${saree.thumbnail}`}
            alt={saree.title}
            style={{ width: "620px" }}
            className="rounded-md bg-cover w-[85%] h-full flex justify-end"
          />
        </Zoom>
      </div>
      <div className="flex flex-col gap-3">
        <h4 className="text-xl font-bold">{saree.title}</h4>
        <p className="text-xl font-bold flex items-center border-b-2 border-gray-300">
          {/* {currency === "USD" ? <><BsCurrencyDollar size={20} />{saree.priceUSD}</> : <><MdCurrencyRupee size={24} />{saree.priceINR}</>} */}
          { new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(currency==="USD"?saree.price:saree.price)}
         
        </p>
        <div>
          <h3 className="text-lg font-bold">Product Description</h3>
          <p className="p-regular-16">{saree.description}</p>
        </div>

        <div className="flex gap-3 ">
          <div>
            <Button className="headermenu" /* onClick={handleCart}  disabled={availableCount >= saree.availableQuantity}*/>Add To Cart</Button>
            {/* {availableCount >= saree.availableQuantity && */} <p className="text-red-600 text-sm font-semibold px-1">Out Of Stock</p>{/* } */}
          </div>
          <Button className="bg-red-500" /* onClick={handleWishlist} */><FaRegHeart size={20} className="pr-2" />Add To WishList</Button>
          <div className="px-2">
            <ShareModel saree={saree} />
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}


const ShareModel = ({ saree }: any) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const fullPath = window.location.origin + location.pathname;

  const handleClose = () => {
    setOpen(false);
  };
  const handleCopyLink = () => {
    navigator.clipboard.writeText(fullPath);
    setOpen(false);
  };

  return (<>
    <Helmet>
      <title>Chennapatnam Pattucheerala Angadi {`${saree.productName}`}</title>
      <meta name="description" content={`Chennapatnam Pattucheerala Angadi - ${saree.productName}. New Collection.`}/>
      <meta property="og:title" content={`Chennapatnam Pattucheerala Angadi - ${saree.productName}`} />
      <meta property="og:url" content={fullPath} />
     {/*  <meta property="og:image" content={`${IMGURL}${saree.thumbnail}`} /> */}
    </Helmet>
      <Dialog open={open} onOpenChange={() => {
        if (!open) {
          setOpen(true)
        } else {
          handleClose()
        }
      } }>
        <DialogTrigger><p className="flex gap-2 items-center text-md font-semibold p-2 bg-yellow-200 rounded-lg">share <FaShare /></p></DialogTrigger>
        <DialogContent className="w-[300px]">
          <DialogTitle>Share Product</DialogTitle>
          <DialogDescription className="flex justify-between">
            <FacebookShareButton url={fullPath}> <FaFacebook size={28} color="blue" /></FacebookShareButton>
            <WhatsappShareButton url={fullPath} title="product image"> <FaSquareWhatsapp size={28} color="green" /></WhatsappShareButton>
            <TwitterShareButton url={fullPath}> <FaXTwitter size={28} color="black" /></TwitterShareButton>
            <Button onClick={handleCopyLink} variant="ghost"><IoIosLink size={28} color="black" /></Button>
          </DialogDescription>
        </DialogContent>
      </Dialog></>
  );
};

export default SareePage;
