import React from 'react'
import { useGetProductByIdQuery } from "../../redux/slice/ProductsApiSlice"
import { useParams } from "react-router-dom"
import ViewProduct from "../../components/shared/admin/components/ViewProduct"

const ViewProductPage = () => {
    const{id}=useParams()
    const { data: product, isError, isLoading, isSuccess, error} = useGetProductByIdQuery(id)
    let content
  
    if (isLoading) content = <p>Loading........</p>
  
    if (isError) content = <p>{product?.result?.message}</p>
    if (!product?.result){
      content= <section >
        <p className="p-4 text-center text-lg font-semibold">
          No Sarees Available By ID
        </p></section>}
    if (isSuccess&& product?.result){
      content=  <ViewProduct saree={product?.result}  />
    }
    return (
      <div className="w-full md:mx-20 md:my-10 border-2 border-gray-300 md:p-4">
      <h5 className="h5-bold mb-4">Product Details</h5>
       {content}
    </div>
    )
}





export default ViewProductPage
