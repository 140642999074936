import {  Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@/redux/store'
import { useEffect, useState } from 'react'
import { SlashIcon } from "@radix-ui/react-icons"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../components/ui/breadcrumb"
import { ProductsResponse, sareeData } from '@/react-app-env'
import axios from 'axios'
import { DEMOAPIURL } from '../constants/appConfig'
import FSTableDataDemo from '../components/shared/Table/FSTableDataDemo'


const SareesByCategory = () => {
  const { currency } = useSelector((state: RootState) => state.counterCurrency);
  const { catName } = useParams();
  const [products,setProducts]=useState<ProductsResponse>({ products: [], total: 0, skip: 0, limit: 0 });
  const [loading,setLoading]=useState(false)
  /*const { data: products, isError, isLoading, isSuccess, error} = useGetCategoryProductsQuery(catName)
   const [productByCategory, {isLoading,isSuccess,isError,error,data:products }] = useGetAllProductsMutation()
  const {data:subCategories} = useGetSubcategoryQuery(catId)
  const headerName = weavesData.filter(item=>item.categoryId===Number(catId))[0].name

  useEffect(()=>{
    productByCategory({catId,pageNumber:0})
  },[productByCategory,catId]) */

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const response = await axios.get(`${DEMOAPIURL}category/${catName}`);
        setProducts(response.data);
        setLoading(false)
      } catch (error) {
        // Handle errors here
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Call the async function
  }, [catName]);

  let content
 /*  if (isLoading) content= <section className="bgcolorgold" ><p className="p-4">Loading...</p></section>;
  if (isError) content = <p>{products?.result?.message}</p>
  if (!products?.result?.length){
    content= <section className="bgcolorgold" >
    <p className="p-4 text-center text-lg font-semibold">
      No Sarees Available Under This Category 
    </p></section>} 
  if (isSuccess&& products?.result?.length>0){*/
  if ( products?.total>0){
    content=  <>
    {/* <h5 className='h5-bold  sm:m-2 md:px-72'>{headerName}</h5> */}
    {/* <BreadcrumbWithCustomSeparator data = {products} /> */}
    <FSTableDataDemo data={products.products} /* subCategories={subCategories?.result} categoryType={headerName} *//>
    </>
  }else{
    content= <section className="bgcolorgold" >
    <p className="p-4 text-center text-lg font-semibold">
      No Sarees Available Under This Category 
    </p></section>
  }

  return (<section className="bgcolorgold" >
    <div>
    {content}
    </div>
    </section>);
};


export function BreadcrumbWithCustomSeparator({data}:{data: sareeData}) {
  return (
    <Breadcrumb className='h5-bold  sm:m-2 md:my-4 md:px-72'>
      <BreadcrumbList>
        <BreadcrumbItem>
          {/* <BreadcrumbLink href="/">Home</BreadcrumbLink> */}
          <Link to="/">Home</Link>
        </BreadcrumbItem>
        <BreadcrumbSeparator>
          <SlashIcon />
        </BreadcrumbSeparator>
        <BreadcrumbItem>
          <BreadcrumbPage className='font-bold text-lg'>{data.categoryName}</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  )
}
export default SareesByCategory;

