import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { Button } from "../../components/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form"
import { Input } from "../../components/ui/input"
import { Textarea } from "../../components/ui/textarea"
import { useFranchiseEnquiryEmailMutation } from "../../redux/slice/admin/ADashboardApiSlice"
import { useEffect, useState } from "react"


const formSchema = z.object({
  fullName: z.string().min(3).max(50),
  email: z.string().email({ message: 'Invalid email format' }).min(2).max(50),
  mobile: z.string().regex(/^\d{10}$/, { message: 'Mobile number must be 10 digits' }),
  message: z.string().min(3),
});

const FranchiseEnquiry = () => {

  const [franchiseEnquiryEmail,{data}] = useFranchiseEnquiryEmailMutation()
  const [state,setState]=useState<boolean>(false)
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      fullName: "",
      email: "",
      mobile: "",
      message: "",
    },
  })
console.log(data);


  useEffect(()=>{
    if(data?.success){ setState(true)}
  },[data])

  async function onSubmit(values: z.infer<typeof formSchema>) {
    await franchiseEnquiryEmail({ ...values })
  }

  return (
    <div className="bgcolorgold">
      <div className="wrapper flex flex-col gap-6">
        <h5 className="h5-bold">
          Franchise Enquiry
        </h5>
        {state?<div className="text-lg font-semibold text-red-600">Thank you for reaching out to Chennapatnam. We will be in touch with you shortly.</div>
        :<div className="flex justify-between md:shadow-md wrapper-weaves gap-10">
          <img src="/assets/images/enquiry.png" alt="enquiry" className=" p-2 md:block hidden" />
        <div className="flex flex-col justify-center gap-2  p-4 w-full">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="fullName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>FullName</FormLabel>
                    <FormControl>
                      <Input placeholder="Full name" {...field} className="bg-yellow-200 focus-visible:ring-0 text-md" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input placeholder="Email" {...field} className="bg-yellow-200 focus-visible:ring-0 text-md" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="mobile"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Mobile</FormLabel>
                    <FormControl>
                      <Input placeholder="Mobile No" {...field} className="bg-yellow-200 focus-visible:ring-0 text-md" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="message"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Message</FormLabel>
                    <FormControl>
                      <Textarea {...field} placeholder="Message" className="bg-yellow-200 focus-visible:ring-0 text-md" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit" className="headermenu">Submit</Button>
            </form>
          </Form>
        </div>
        </div>}

      </div>
    </div>
  );
};

export default FranchiseEnquiry;
