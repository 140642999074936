

import * as z from "zod"

const DesLong = (value: string) => {
    const lines = value.split("\n").filter((line) => line.trim() !== "");
    return lines.length >= 0;
  };
  const DesShort = (value: string) => {
    const lines = value.split("\n").filter((line) => line.trim() !== "");
    return lines.length >= 0;
  };
  const isImage = (file: File): boolean => {
    return typeof file.type === 'string' && file.type.startsWith('image/');
  };
  
  const isPDF = (file: File): boolean => {
    return typeof file.type === 'string' && file.type === 'application/pdf';
  };
  
  const fileSchema = z.custom<File>((file: unknown) => {
    if (file instanceof File) {
      if (isImage(file) || isPDF(file)) {
        return file;
      } else {
        throw new Error('Invalid file type. Please upload an image or a PDF.');
      }
    } else {
      throw new Error('Invalid file type. Please upload an image or a PDF.');
    }
  });
  
  


const productFormSchema = z.object({
    productName: z.string().min(3, {
      message: "Name must be at least 3 characters.",
    }),
    // productDescription: z.string().min(2, {
    //   message: "Description must be at least 2 characters.",
    // }),
    productDescription: z.string().refine((value) => DesLong(value), {
      message: "Description must be at least 8 lines.",
    }),
    categoryId: z.number().positive({
      message: "categoryId must be a positive number.",
    }),
    colorId: z.number().positive({
      message: "colorId must be a positive number.",
    }),
    availableQuantity: z.number()/* .positive({
      message: "availableQuantity must be a positive number.",
    }) */,
   /*  colorId:z.array(z.number().positive()).refine(data => data.length > 0, {
      message: 'Please select at least one color.',
    }),
    descriptionShort: z.string().refine((value) => DesShort(value), {
      message: "Description must be at least 3 lines.",
    }), */
    priceINR: z.number().positive({
      message: "priceINR must be a positive number.",
    }),
    soldQuantity: z.number()/* .positive({
      message: "soldQuantity must be a positive number.",
    }) */,
    subcategoryId: z.number().positive({
      message: "subcategoryId must be a positive number.",
    }),
    discount: z.number(),
    /* thumbnail: z.string().min(2, {
      message: "thumbnail must be at least 2 characters.",
    }),
  
     image: z.instanceof(FileList).optional(), */
    thumbnail:z.string(),
    priceUSD: z.number().positive({
      message: "discount must be a positive number.",
    }),
  });
 
  export default productFormSchema