
/* import { useGetProductByIdQuery } from "../../redux/slice/ProductsApiSlice"
import { useParams } from "react-router-dom" */
import ProductForm from "../../components/shared/admin/components/ProductForm"


const NewProduct = () => {
 
    return (
        <div className="w-full md:mx-20 md:my-10 border-2 border-gray-300 md:p-4">
          <h5 className="h5-bold mb-4">Add New Product</h5>
       <ProductForm type="Create" />
    </div>
    )
}

export default NewProduct