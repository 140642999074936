import { useEffect, useState } from "react";
import { Button } from "../../components/ui/button";
import { useGetAllProductsMutation } from "../../redux/slice/ProductsApiSlice";
import {  useNavigate } from "react-router-dom";
import { DataTable } from "../../components/shared/admin/components/Table/data-table";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { useGetAllCategoryQuery } from "../../redux/slice/CategoryApiSlice";
import { useGetCancelledOrdersAQuery, useGetClosedOrdersAQuery } from "../../redux/slice/admin/ACreateOrderApiSlice";
import { orderColumns } from "../../components/shared/admin/components/OrdersTable/OrderColumns";



const ClosedOrders = () => {
   const { isSuccess, isError, error,isLoading, data: getCancelledorders } = useGetClosedOrdersAQuery("ordersList", {pollingInterval: 6000,refetchOnFocus: true,refetchOnMountOrArgChange: true,})
  
   let content
   if (isLoading) content= <p className="p-4">Loading...</p>
   if (isError) content = <p>{getCancelledorders?.result?.message}</p>
   if (!getCancelledorders?.result?.length){
     content= <p className="p-4 text-center text-lg font-semibold w-full">
       NO CLOSED ORDERS YET
     </p>}
   if (isSuccess&& getCancelledorders?.result?.length>0){
     content=  <>
     <div className="flex justify-between p-4">
        <h5 className='h5-bold'>CLOSED ORDERS</h5>
      </div>
      <div className=" p-4 rounded-md border-2 md:overflow-hidden shadow-lg bg-green-50">
        {getCancelledorders?.result?.length>0 && <DataTable data={getCancelledorders?.result} columns={orderColumns}/>}
      </div>
     </>
   }
  return (<section className="md:ml-52">
    <main className=" border-2  bg-red-50  border-gray-500 md:mx-8 md:my-12 md:p-4">
      {content}
    </main>
    </section>)
}

export default ClosedOrders

