import { DotsHorizontalIcon } from "@radix-ui/react-icons"
import { Row } from "@tanstack/react-table"

import { Button } from "../../../../ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "../../../../ui/dropdown-menu"
import { sareeData } from "@/react-app-env"
import { useNavigate } from "react-router-dom"
import { FiEdit } from "react-icons/fi";
import { useDeleteProductAMutation } from "../../../../../redux/slice/admin/AProductsApiSlice"
import { AlertDialog, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "../../../../../components/ui/alert-dialog"
import { IoIosEye } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useEffect} from "react"
import { IoMdImages } from "react-icons/io";

interface DataTableRowActionsProps<TData> {
  row: Row<sareeData>
}

export function DataTableRowActions<TData>({
  row,
}: DataTableRowActionsProps<TData>) {
  const product = row.original;
  const navigate = useNavigate();

 /*  const [deleteProductA,{isSuccess,data}] =useDeleteProductAMutation()
  const handleRemoveProduct =async(id:number)=>{
    const shouldDelete = window.confirm('Are you sure you want to delete this product?');
    if (shouldDelete) {
      await deleteProductA(id);
      navigate("/admin/dashboard/products")
    } 
  } 
  useEffect(()=>{
    if(isSuccess&& data?.success){
      navigate("/admin/dashboard/products")}
    },[isSuccess,navigate,data])*/

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
        >
          <DotsHorizontalIcon className="h-4 w-4" />
          <span className="sr-only">Open menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px] flex-col flex gap-1">
      <DropdownMenuItem onClick={() => navigate(`/admin/dashboard/uploadimages/${product.id}`)}>Upload More Images <IoMdImages className="w-16 " size={20}/></DropdownMenuItem>
       <DropdownMenuItem onClick={() => navigate(`/admin/dashboard/view/${product.id}`)}>View <IoIosEye className="w-16 " size={20}/></DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigate(`/admin/dashboard/edit/${product.id}`)}>
          Edit<FiEdit className="w-16 " size={16}/>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <AlertDialogDemo id={product.id}/>
         {/* <DropdownMenuItem onClick={()=>handleRemoveProduct(product.id)}>
          Delete <RiDeleteBin6Line className="w-16 " size={16}/> 
        </DropdownMenuItem> */}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function AlertDialogDemo({ id }: { id: number}) {
  const [deleteProductA,{isSuccess,data}] =useDeleteProductAMutation()
  const navigate = useNavigate();

  useEffect(()=>{
    if(isSuccess&& data?.success){
     /*  navigate("/admin/dashboard/products") */
      window.location.reload()
    }},[isSuccess,navigate,data])

   const handleRemoveProduct =async()=>{
    await deleteProductA(id);
   }
    return (
      <AlertDialog >
        <AlertDialogTrigger asChild>
          <Button variant="ghost" className="pl-0">Delete <RiDeleteBin6Line size={18} /></Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently remove the Product from app.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <Button onClick={handleRemoveProduct}>Continue</Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }

// interface DataTableRowActionsProps<TData> {
//     row: Row<sareeData>;
//   }
  
//   export function DataTableRowActions<TData>({
//     row,
//   }: DataTableRowActionsProps<TData>) {
//     const product = row.original;
//     const navigate = useNavigate();
//     const [deleteProductA, { isSuccess, isError, data }] = useDeleteProductAMutation();
//     const [isAlertDialogOpen,setIsAlertDialogOpen]=useState(false)
 
  
//     const handleDeleteClick = () => {
//       // Open the alert dialog directly when "Delete" is clicked
//       setIsAlertDialogOpen(true);
//     };
  
//     return (
//       <DropdownMenu>
//         <DropdownMenuTrigger asChild>
//           <Button
//             variant="ghost"
//             className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
//           >
//             <DotsHorizontalIcon className="h-4 w-4" />
//             <span className="sr-only">Open menu</span>
//           </Button>
//         </DropdownMenuTrigger>
//         <DropdownMenuContent align="end" className="w-[160px]">
//           <DropdownMenuItem onClick={() => navigate(`/admin/dashboard/edit/${product.id}`)}>
//             Edit<FiEdit className="w-16 " size={20} />
//           </DropdownMenuItem>
//           <DropdownMenuItem onClick={() => navigate(`/admin/dashboard/view/${product.id}`)}>View Product</DropdownMenuItem>
//           <DropdownMenuItem onClick={() => handleDeleteClick()}>
//             Delete 
//             <DropdownMenuShortcut>⌘⌫</DropdownMenuShortcut>
//           </DropdownMenuItem>
//         </DropdownMenuContent>
  
//         {/* Render the alert dialog directly */}
//         {isAlertDialogOpen && (
//           <AlertDialogDemo id={product.id} onClose={() => setIsAlertDialogOpen(false)} />
//         )}
//       </DropdownMenu>
//     );
//   }
  
//   export function AlertDialogDemo({ id, onClose }: { id: number; onClose: () => void }) {
//     const [deleteProductA, { isSuccess, isError, data }] = useDeleteProductAMutation();
  
//     const handleRemoveProduct = async () => {
//       await deleteProductA(id);
//       onClose();
//     };
  
//     return (
//       <AlertDialog>
//         <AlertDialogContent>
//           <AlertDialogHeader>
//             <AlertDialogTitle>Are you sure?</AlertDialogTitle>
//             <AlertDialogDescription>
//               This action cannot be undone. This will permanently remove the Product from the Web.
//             </AlertDialogDescription>
//           </AlertDialogHeader>
//           <AlertDialogFooter>
//             <AlertDialogCancel>Cancel</AlertDialogCancel>
//             <Button onClick={handleRemoveProduct}>Continue</Button>
//           </AlertDialogFooter>
//         </AlertDialogContent>
//       </AlertDialog>
//     );
//   }
  