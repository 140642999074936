import Weaves, { CategoriesHome } from '../components/shared/Home/Weaves'
import Video, { SliderDemo } from '../components/shared/Home/Video'
import ShopByPrice from '../components/shared/Home/ShopByPrice'
import Explore from '../components/shared/Home/Explore'
import NewCollection from '../components/shared/Home/NewCollection'
import Download from '../components/shared/Home/Download'
import MyChatBot from '../components/shared/Home/chatbot.tsx/Chatbot'
import PriceByShop from '../components/shared/Home/PriceByShop'


const Home = () => {
  return (
    <>
    <SliderDemo/>
    {/* <CategoriesHome/> */}
   {/*  <NewCollection /> */}
    <ShopByPrice/>
    {/* <PriceByShop/>  */}
    {/* <Explore/> */}
   {/*  <Download/>  */}
    <MyChatBot/>
    </>
  )
}


export default Home